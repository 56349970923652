<template>
<div class="info">
	<common-header
		type="allow"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">お知らせ</h1>
	</common-header>

	<article class="info-container sugotoku" id="js_info_container">
		<section class="info__inner">
			<h2 class="info__title">2021.07.12</h2>
			<div class="info__box">
				<p>日頃より頭痛ーるforスゴ得をご利用いただきまして誠にありがとうございます。<br>2021年8月末日をもって、WEB版の痛み記録とプロフィール登録機能は、提供を終了いたします。</p>
				<p>今後はAppStore版頭痛ーるをインストールして頂き、スゴ得ログイン後、痛み記録とプロフィール登録機能をご利用頂きますようよろしくお願いいたします。</p>
				<p>引継ぎ頭痛ーるforスゴ得をよろしくお願いいたします。</p>
			</div>
		</section>

		<div class="info__back-button--wrapper">
			<p class="info__back-button" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">TOPにもどる</p>
		</div>
	</article>
</div>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'Info',
	components: {
		CommonHeader
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.info {
	padding-top: 44px;	// ヘッダーの高さ

	&-container {
		font-family: $family-primary;
		padding: $spacing-10 $spacing-10 0;
		background-color: $background-primary;
		box-sizing: border-box;
	}
	&__title {
		width: 280px;
		margin: 0 auto;
		font-family: $family-primary;
		font-size: $font-size-16;
		color: $text-bold;
		font-weight: bold;
		text-shadow: 1px 1px 0px $_white;
	}
	&__box {
		width: 280px;
		margin: $spacing-10 auto 0;
		border: 1px solid $line-primary;
		border-radius: 10px;
		padding: $spacing-10;
		p {
			margin: $spacing-10 0;
			font-size: $font-size-14;
			color: $text-primary;
			line-height: $line-height;
		}
	}
	&__back-button {
		position: relative;
		display: block;
		font-size: $font-size-18;
		font-weight: bold;
		line-height: 1.4; // NOTE: 独自デザインによる行間
		margin: 0 auto;
		padding: $spacing-8 0;
		width: 80%;
		text-align: center;
		color: $_white;
		border-radius: 6px;
		background-color: $about-rightnow-button;
		text-decoration: none;

		&--wrapper {
			padding: $spacing-20 0 $spacing-10;
		}
	}
	&__appdownload {
		margin-top: $spacing-20;
		a {
			display: block;
			img { width: 100%; }
		}
	}
}
</style>
